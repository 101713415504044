import { ErrorBoundary } from "react-error-boundary";
import { components } from "../../../api/schema";
import DefaultFallback from "../../../components/DefaultFallback";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import AutomationSwitch from "./AutomationSwitch";
import SaveButtonContainer from "./SaveButtonContainer";
import { Policy } from "./utils";

interface SaveAndAutomateButtonsProps {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  selectedPolicy: Policy | undefined;
  selectedHPAPolicy: string | undefined;
  fetchWorkloads: () => void;
  isAutomate: boolean;
  setIsAutomate: React.Dispatch<React.SetStateAction<boolean>>;
  autoFieldToUpdate: "auto" | "hpaAuto" | "rightSizeAuto";
  scaleOpsProduct?: ScaleOpsProduct;
}

const SaveAndAutomateButtons = ({
  selectedWorkload,
  selectedPolicy,
  selectedHPAPolicy,
  fetchWorkloads,
  isAutomate,
  setIsAutomate,
  autoFieldToUpdate,
  scaleOpsProduct,
}: SaveAndAutomateButtonsProps) => {
  return (
    <div className="flex w-full justify-end gap-4">
      <ErrorBoundary
        fallback={<DefaultFallback message="Failed to Policy Tunning Action Buttons. Please check your setup" />}
      >
        <SaveButtonContainer
          selectedWorkload={selectedWorkload}
          selectedPolicy={selectedPolicy}
          scaleOpsProduct={scaleOpsProduct}
          selectedHPAPolicy={selectedHPAPolicy}
        />
        <AutomationSwitch
          selectedWorkload={selectedWorkload}
          isAutomate={isAutomate}
          setIsAutomate={setIsAutomate}
          fetchWorkloads={fetchWorkloads}
          scaleOpsProduct={scaleOpsProduct}
          autoFieldToUpdate={autoFieldToUpdate}
        />
      </ErrorBoundary>
    </div>
  );
};

export default SaveAndAutomateButtons;

import { Alert, AlertProps, Paper, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataGrid, GridRowParams, GridRowsProp, GridSortDirection } from "@mui/x-data-grid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { ApiResponse } from "openapi-typescript-fetch/dist/cjs/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { ArrayParam, BooleanParam, StringParam, useQueryParam } from "use-query-params";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import { useWorkloadsContext } from "../../WorkloadsContext";
import { ScaleOps } from "../../api/api";
import {
  getConfig,
  GetConfigResponse,
  getDashboardByNamespace,
  GetDashboardByNamespaceResponse,
} from "../../api/fetcher";
import { components } from "../../api/schema";
import useGetApi from "../../api/useGetApi";
import PolicyTuning from "../../pages/Overview/PolicyTuning/PolicyTuning";
import useResetRecommendation from "../../pages/Overview/PolicyTuning/mutation/useResetRecommendation";
import useUpdateAutomation from "../../pages/Overview/PolicyTuning/mutation/useUpdateAutomation";
import { FeatureEnabled, IsBigCluster } from "../../utils/FeaturesHelper";
import { adjustedDayjs } from "../../utils/dateAndTimeUtils";
import { HAS_GPU_SUPPORT } from "../../utils/developmentFeatureFlags";
import { FOOTER_HEIGHT, getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT } from "../../utils/styleUtils";
import { ScaleOpsProduct } from "../../utils/typesUtils";
import useIsReadOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import useStateWithLocalStorage from "../../utils/useStateWithLocalStorage";
import useStateWithSessionStorage from "../../utils/useStateWithSessionStorage";
import DefaultFallback from "../DefaultFallback";
import useWindowSize from "../useWindowSize";
import EditToolbar, { OverviewTableColumns, WorkloadAnnotation } from "./BulkPolicyEditorToolbar";
import useColumns from "./Columns/GridColumns";
import GitConfigPopup from "./GitConfigPopup";
import NodeActionRecommendation from "./NodeActionRecommendation";
import OverviewExportCSVButton from "./OverviewExportCSVButton";
import usedGetCappedStatuses, { serializeCappedStatus } from "./OverviewHooks/useGetCappedStatuses";
import { OverviewTableFields, SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM } from "./overviewUtils";
import useAttachPolicyToWorkload from "./useAttachPolicyToWorkload";
import useFilterQueryParams, { FilterParamObject } from "./useFilterQueryParams";
import useGetUniquePolicyNamesByProduct from "./useGetUniquePolicyNamesByProduct";
import useRestoreClusterPolicy from "./useRestoreClusterPolicy";
import useRestoreNamespacesPolicy from "./useRestoreNamespacesPolicy";
import useUpdatePolicyByCluster from "./useUpdatePolicyByCluster";
import { useUpdateNamespaceInBulk } from "./useUpdatePolicyByNamespace";
import { SCALEOPS_SYSTEM_NAMESPACE, SelectRowsOnInitialLoading } from "./utils";

dayjs.extend(dayjsPluginUTC);

const SEARCH_LOGIC: "OR" | "AND" = "AND";
const OVERVIEW_PAGE_SIZE_LOCAL_STORAGE_KEY = "overviewPageSize";
const HAS_NODE_ACTIONS_RECOMMENDATION_FF = false;
const HAS_OVERRIDE_WORKLOADS_ENABLED = false;
const SELECTED_COLUMNS_LOCAL_STORAGE_KEY = "selectedOverviewPageColumns";
const ASC = "asc";
const DESC = "desc";
const STOP_ANIMATION_THRESHOLD = 200;

const { queryKey: getConfigQueryKey, queryFn: getConfigQueryFn } = getConfig();

const INITIAL_SORT_MODEL = [
  {
    field: String(OverviewTableFields.SavingsAvailable),
    sort: DESC as GridSortDirection,
  },
];

const numericSort = (
  workLoadsToSet: components["schemas"]["UtilsWorkload"][],
  sortDirection: string,
  property: keyof components["schemas"]["UtilsWorkload"],
  shouldShowInactiveLast?: boolean
) =>
  workLoadsToSet.sort((a, b) => {
    const aValue = Number(a[property]);
    const bValue = Number(b[property]);
    if (shouldShowInactiveLast && a.isReadyRecommendation !== b.isReadyRecommendation) {
      return a.isReadyRecommendation ? -1 : 1;
    }
    if (aValue < bValue) {
      return sortDirection === ASC ? -1 : 1;
    } else if (aValue > bValue) {
      return sortDirection === ASC ? 1 : -1;
    } else {
      return -2;
    }
  });

const getKeys = (scaleOpsProduct: ScaleOpsProduct | undefined) => {
  if (scaleOpsProduct) {
    return `${SELECTED_COLUMNS_LOCAL_STORAGE_KEY}-${scaleOpsProduct?.toUpperCase()}`;
  }
  return SELECTED_COLUMNS_LOCAL_STORAGE_KEY;
};

const setInitialSelectedColumns = ({
  localStorageSelectedColumns,
  initialColumns,
  availableColumns,
}: {
  localStorageSelectedColumns: string[];
  initialColumns: string[];
  availableColumns: string[];
}) =>
  localStorageSelectedColumns &&
  localStorageSelectedColumns.length > 0 &&
  localStorageSelectedColumns.every((c) => availableColumns.includes(c as OverviewTableColumns))
    ? localStorageSelectedColumns
    : initialColumns;

const getLowerCaseWorkloadName = (workload: components["schemas"]["UtilsWorkload"]) => {
  return `${workload.namespace}/${workload.workloadName}`.toLowerCase();
};

export const getDisplayWorkloadName = (name: string, hideSuffix?: boolean, displayName?: string | undefined) =>
  displayName ??
  name
    .replace(/^Family/, "argo-rollout")
    .replace(/^scaleops-rollout-/, "")
    .replace(hideSuffix ? /-(?!.*-).*$/ : "", "");

const availableColumns = Object.values(OverviewTableColumns).filter(
  (column) => HAS_GPU_SUPPORT || column != OverviewTableColumns.GpuRequests
);

interface DashOverUnderProvisioningFilters {
  overProvisioned?: boolean;
  underProvisioned?: boolean;
  priorityClassName?: string;
  optimizationGap?: string;
  namespace?: string;
  labels?: string;
}

type DashWorkloadWithIssues = components["schemas"]["UtilsWorkload"] & {
  issues?: components["schemas"]["DashIssues"];
} & DashOverUnderProvisioningFilters;

export interface WorkloadTableFilter {
  type: string;
  valueName: string;
}

export interface WorkloadTableFilterToHandle {
  type: string;
  namespaces: string[];
  labels: string[];
  annotations: string[];
  priorityClass: string[];
  optimizationGaps: string[];
  cappedStatuses: string[];
}

interface Props {
  setDisableAnimation: React.Dispatch<React.SetStateAction<boolean>>;
  scaleOpsProduct?: ScaleOpsProduct;
}

const initialColumns = [
  OverviewTableColumns.SavingsAvailable,
  OverviewTableColumns.CpuRequests,
  OverviewTableColumns.MemoryRequests,
  OverviewTableColumns.Replicas,
  OverviewTableColumns.Policy,
];

const HPAInitialColumns = [
  OverviewTableColumns.SavingsAvailable,
  OverviewTableColumns.CpuRequests,
  OverviewTableColumns.MemoryRequests,
  OverviewTableColumns.Replicas,
  OverviewTableColumns.MinReplicasDiff,
  OverviewTableColumns.Policy,
];

export default function WorkloadStatusByNamespaces({ setDisableAnimation, scaleOpsProduct }: Props) {
  const api = useGetApi();
  const windowSize = useWindowSize();
  const dashboardByNamespace = getDashboardByNamespace();
  const isBigCluster = IsBigCluster();

  const {
    overriddenWorkloads,
    overriddenWorkloadsIds,
    setSelectedNamespaceIds,
    excludeFromNamespaceAutomationIds,
    setExcludeFromNamespaceAutomationIds,
    updateOverriddenWorkloads,
    updateOverriddenWorkloadsByUpdateFunction,
    deleteOverriddenWorkloadsProperties,
    resetOverriddenWorkloads,
  } = useWorkloadsContext();

  const isReadOnlyFrontEnd = useIsReadOnlyFrontEnd();

  const updateNamespaceBulk = useUpdateNamespaceInBulk();
  const updatePolicyByCluster = useUpdatePolicyByCluster();
  const restorePolicyOnCluster = useRestoreClusterPolicy();
  const restorePolicyOnNamespace = useRestoreNamespacesPolicy();

  const uniquePolicyNamesByProduct = useGetUniquePolicyNamesByProduct({ scaleOpsProduct });

  const originalOrder = useRef<(string | undefined)[]>([]);
  const originalSortOrderCluster = useRef<string | undefined>();

  const [lastTimeWorkloadRowWasClicked, setLastTimeWorkloadRowWasClicked] = useStateWithLocalStorage<number>({
    localStorageKey: "lastTimeWorkloadRowWasClicked",
    defaultValue: 0,
  });

  const [workloadRowClickCount, setWorkloadRowClickCount] = useStateWithLocalStorage<number>({
    localStorageKey: "workloadRowClickCount",
    defaultValue: 0,
  });

  const [didUserAutomateFirstWorkload, setDidUserAutomateFirstWorkload] = useStateWithSessionStorage<boolean>({
    sessionStorageKey: "didUserAutomateFirstWorkload",
    defaultValue: false,
    valueFormatter: (value) => value === "true",
  });

  const [selectRowsOnInitialLoading, setSelectRowsOnInitialLoading] = useQueryParam(
    "selectRowsOnInitialLoading",
    StringParam
  );

  const [highlightedSelection, setHighlightedSelection] = useState<string | undefined>();
  const [searchTerms] = useQueryParam("searchTerms", ArrayParam);
  const [currentSearchTerm] = useQueryParam("currentSearchTerm", StringParam);
  const [namespacesParams] = useQueryParam("namespaces", ArrayParam);
  const [isNamespacesExclude] = useQueryParam("isNamespacesExclude", BooleanParam);
  const [policiesParams] = useQueryParam("policy", ArrayParam);
  const [isAutomated] = useQueryParam("isAutomated", BooleanParam);
  const [isUnAutomated] = useQueryParam("isUnAutomated", BooleanParam);
  const [hasMinReplicasSavings] = useQueryParam("hasMinReplicasSavings", BooleanParam);
  const [hasHpaAutomatedPolicy] = useQueryParam("hasHpaAutomatedPolicy", BooleanParam);
  const [hasHpaUnAutomatedPolicy] = useQueryParam("hasHpaUnAutomatedPolicy", BooleanParam);
  const [hasHpa] = useQueryParam("hasHpa", BooleanParam);
  const [isMisconfiguredHpa] = useQueryParam("isMisconfiguredHpa", BooleanParam);
  const [isHpaPredictable] = useQueryParam("isHpaPredictable", BooleanParam);
  const [isOverProvisioned] = useQueryParam("overProvisioned", BooleanParam);
  const [isUnderProvisioned] = useQueryParam("underProvisioned", BooleanParam);
  const [isPdb] = useQueryParam("isPdb", BooleanParam);
  const [outOfMemory] = useQueryParam("outOfMemory", BooleanParam);
  const [priorityClassNamesParams] = useQueryParam("priorityClassNames", ArrayParam);
  const [optimizationGapsParams] = useQueryParam("optimizationGaps", ArrayParam);
  const [availableSavingsFilter] = useQueryParam("availableSavingsFilter", StringParam);
  const [selectedLabelNames] = useQueryParam("labels", ArrayParam);
  const [typesParams] = useQueryParam("types", ArrayParam);
  const [sortField, setSortField] = useQueryParam("sortField", StringParam);
  const [sortDirection, setSortDirection] = useQueryParam("sortDirection", StringParam);
  const [isInitialSortModel, setIsInitialSortModel] = useState<boolean>(true);
  const [isWarningTooltipOpen, setIsWarningTooltipOpen] = useState<boolean>(false);

  const [namespaceAnnotationParam] = useQueryParam("namespaceAnnotation", ArrayParam);

  const [namespaceAnnotationLogical] = useQueryParam("namespaceAnnotationLogical", StringParam);
  const [namespaceAnnotationExclude] = useQueryParam("isNamespaceAnnotationExclude", BooleanParam);
  const [openFirstRowWorkloadOverview, setOpenFirstRowWorkloadOverview] = useQueryParam(
    "openFirstRowWorkloadOverview",
    BooleanParam
  );

  const resetRecommendation = useResetRecommendation();

  const attachPolicyToWorkload = useAttachPolicyToWorkload();

  const [selectedColumns, setSelectedColumns] = useState<(string | undefined)[]>([]);

  const defaultWorkloadsFetchIntervalMs = 12000;
  const demoVersionWorkloadsFetchIntervalMs = 5000;

  const [workloadsLabels, setWorkloadsLabels] = useState<string[]>([]);
  const [workloadsAnnotations, setWorkloadsAnnotations] = useState<WorkloadAnnotation[]>([]);
  const [namespaceAnnotations, setNamespaceAnnotations] = useState<string[]>([]);

  const { data: workloadsCappingStatusesData } = usedGetCappedStatuses();
  const workloadCappingStatuses = workloadsCappingStatusesData?.cappedStatusFilterValues ?? [];
  const serializedCappingStatuses = workloadCappingStatuses.map(serializeCappedStatus);

  const [rows, setRows] = useState<GridRowsProp<components["schemas"]["UtilsWorkload"]>>([]);
  const [selectAllRowsOnInitialLoad, setSelectAllRowsOnInitialLoad] = useQueryParam(
    "selectAllRowsOnInitialLoad",
    BooleanParam
  );

  const [firstRowId, setFirstRowId] = useState<string | undefined>();
  const [rowsWasInitialized, setRowsWasInitialized] = useState<boolean>(false);
  const [allRows, setAllRows] = useState<GridRowsProp<components["schemas"]["UtilsWorkload"]>>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: OVERVIEW_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: 25,
    valueFormatter: (value) => parseInt(value),
  });

  const [rowCount, setRowCount] = useState<number>(0);

  const [policies, setPolicies] = useState<components["schemas"]["V1alpha1Policy"][]>([]);

  const [selectedRows, setSelectedRows] = useState<components["schemas"]["UtilsWorkload"][] | undefined>(undefined);

  const [gitWorkflow, setGitWorkflow] = useState<components["schemas"]["UtilsWorkload"] | undefined>();

  const [snackbar, setSnackbar] = useState<
    | (Pick<AlertProps, "severity"> & {
        children: { message?: string };
      })
    | null
  >(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const [namespacesForFilter, setNamespacesForFilter] = useState<string[]>([]);
  const [typesForFilter, setTypesForFilter] = useState<string[]>([]);
  const [priorityClassNames, setPriorityClassNames] = useState<string[]>([]);
  const [optimizationGaps, setOptimizationGaps] = useState<string[]>([]);

  const [workloadFetchIntervalMs, setWorkloadFetchIntervalMs] = useState<number>(defaultWorkloadsFetchIntervalMs);

  const [isPolicyTuningDialogOpen, setIsPolicyTuningDialogOpen] = useState<boolean>(false);

  const [selectedWorkloadOverviewId, setSelectedWorkloadOverviewId] = useQueryParam(
    SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM,
    StringParam
  );
  const [selectedWorkload, setSelectedWorkload] = useState<components["schemas"]["UtilsWorkload"] | undefined>(
    undefined
  );

  const [hideSuffix, setHideSuffix] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string | undefined>();

  const queryClient = useQueryClient();
  const filterQueryParams: FilterParamObject = useFilterQueryParams();

  const { data: configData } = useQuery<GetConfigResponse, Error>({
    queryKey: [getConfigQueryKey],
    queryFn: getConfigQueryFn,
  });

  const wasInstalledInTheLast3Days =
    !!configData?.installDate && adjustedDayjs().diff(adjustedDayjs(configData.installDate), "day") <= 3;

  const { data, isLoading } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, filterQueryParams, scaleOpsProduct],
    queryFn: () => dashboardByNamespace.queryFn({ ...filterQueryParams, scaleOpsProduct }),
    refetchInterval: workloadFetchIntervalMs,
  });

  useEffect(() => {
    resetOverriddenWorkloads();

    const selectedColumnsLocalStorageKey = getKeys(scaleOpsProduct);

    const localStorageSelectedColumns = JSON.parse(
      localStorage.getItem(selectedColumnsLocalStorageKey) as string
    ) as string[];

    setSelectedColumns(
      setInitialSelectedColumns({
        localStorageSelectedColumns,
        initialColumns: scaleOpsProduct === ScaleOpsProduct.HPA ? HPAInitialColumns : initialColumns,
        availableColumns,
      })
    );
  }, [scaleOpsProduct]);

  useEffect(() => {
    if (selectedWorkloadOverviewId && selectedWorkloadOverviewId !== selectedWorkload?.id) {
      const selectedWorkload = data?.workloads?.find((wl) => wl.id === selectedWorkloadOverviewId);
      if (selectedWorkload) {
        setSelectedWorkload(selectedWorkload);
        setIsPolicyTuningDialogOpen(true);
      }
    }
  }, [selectedWorkloadOverviewId, rows]);

  useEffect(() => {
    if (openFirstRowWorkloadOverview && rows && rows.length > 0 && rows[0].id) {
      setOpenFirstRowWorkloadOverview(false);
      setSelectedWorkloadOverviewId(rows[0].id);
    }
  }, [rows, openFirstRowWorkloadOverview]);

  useEffect(() => {
    const hideReadOnlyToaster = localStorage.getItem("hideReadOnlyToaster");

    if (
      wasInstalledInTheLast3Days &&
      data?.totalNamespaceSummary?.automatedWorkloads === 0 &&
      !hideReadOnlyToaster &&
      !didUserAutomateFirstWorkload
    ) {
      localStorage.setItem("hideReadOnlyToaster", "true");

      toast.success(
        <div className="flex flex-col gap-2 relative">
          <Typography variant="body2" className="flex gap-1 justify-center text-black" fontWeight={700}>
            <ScaleopsIcon
              width={30}
              height={30}
              className="hide info-icon-animation fixed top-[-8px] left-[-8px]"
              style={{ zIndex: 99999 }}
            />
            <span className="text-guideline-darkPurple flex gap-1">ScaleOps</span> is running in{" "}
            <span className="text-guideline-darkPurple flex gap-1">Read-Only</span>
          </Typography>
          <Typography variant="caption" className="text-black">
            <div className="text-center">
              Explore and discover the full potentials of your cluster.
              {!isReadOnlyFrontEnd && (
                <>
                  <br />
                  <br />
                  To activate the optimization, <br />
                  <b>start automating your workloads</b>.
                </>
              )}
            </div>
          </Typography>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
          icon: false,
          style: {
            background: "#F5F5F5",
            width: 450,
          },
        }
      );
    }
  }, [wasInstalledInTheLast3Days, data, didUserAutomateFirstWorkload]);

  useEffect(() => {
    if (selectedRows) {
      const selectedRowsToSet = selectedRows.filter((row) => {
        return data?.workloads?.find((wl) => wl.id === row.id) !== undefined;
      });

      setSelectedRows(selectedRowsToSet);
    }
  }, [data?.workloads]);

  useEffect(() => {
    setSelectedRows([]);
  }, [currentSearchTerm, searchTerms]);

  useEffect(() => {
    const filterQueryParamsKeys = Object.keys(filterQueryParams);
    if (selectRowsOnInitialLoading && allRows && allRows.length > 0 && filterQueryParamsKeys.length > 0) {
      const slelectedRows =
        selectRowsOnInitialLoading === SelectRowsOnInitialLoading.All ? allRows : allRows.filter((row) => !row.auto);
      setSelectedRows(slelectedRows as components["schemas"]["UtilsWorkload"][]);
      setSelectRowsOnInitialLoading(selectRowsOnInitialLoading);
    }
  }, [allRows, filterQueryParams]);

  useEffect(() => {
    setSelectedRows([]);
  }, [filterQueryParams]);

  useEffect(() => {
    if (selectedColumns) {
      const selectedColumnsLocalStorageKey = getKeys(scaleOpsProduct);
      localStorage.setItem(selectedColumnsLocalStorageKey, JSON.stringify(selectedColumns));
    }
  }, [selectedColumns]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/labels/")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.priorityClasses !== undefined) {
            setPriorityClassNames(response.data.priorityClasses);
          }
          if (response.data.optimizationEvents !== undefined) {
            setOptimizationGaps(response.data.optimizationEvents);
          }
          if (response.data.labels !== undefined) {
            const labels = [...response.data.labels, ...(response.data?.ownerLabels ?? [])];
            let uniqueLabels = [...new Set(labels)];

            const keyAndValueCombinationsToExclude = ["app.kubernetes.io/instance=scaleops"];

            uniqueLabels = uniqueLabels.filter((label) => {
              return !keyAndValueCombinationsToExclude.includes(label);
            });

            setWorkloadsLabels(uniqueLabels);
          }
          if (response.data.annotations !== undefined) {
            const ownerAnnotations = response.data?.ownerAnnotations ?? [];
            const annotations = [...response.data.annotations, ...ownerAnnotations];
            const uniqueAnnotations = [...new Set(annotations)];

            const keyAndValueCombinationsToExclude = [
              "app.kubernetes.io/instance=scaleops",
              "app.kubernetes.io/managed-by=Helm",
              "app.kubernetes.io/name=scaleops",
            ];

            const keysToExclude = [
              "kubectl.kubernetes.io/last-applied-configuration",
              "deployment.kubernetes.io/revision",
              "kubectl.kubernetes.io/restartedAt",
              "scaleops.sh/post39",
            ];

            const annotationsKeyValues = uniqueAnnotations
              .filter((annotation) => !keyAndValueCombinationsToExclude.includes(annotation))
              .map((annotation) => {
                const keyValue = annotation.split("=", 2);
                return { key: keyValue[0], value: keyValue[1] };
              })
              .filter((keyValue) => {
                return !keysToExclude.includes(keyValue.key);
              });

            setWorkloadsAnnotations(annotationsKeyValues);
          }
          if (response.data.types !== undefined) {
            setTypesForFilter(response.data.types);
          }
        }
      })
      .catch((error) => console.error(error));
  }, [api]);

  // const updateNamespaceAutomation = useUpdateNamespaceAutomation();

  const [namespaceObjects, setNamespaceObjects] = useState<components["schemas"]["V1Namespace"][]>([]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/namespaces/")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.namespaces != undefined) {
            setNamespaceObjects(response.data.namespaces);
            setNamespacesForFilter(
              response.data.namespaces
                .filter((ns) => ns !== undefined)
                .map((ns: components["schemas"]["V1Namespace"]) => ns.metadata?.name ?? "")
                .filter((ns) => ns != "")
            );
          }
        }
      })
      .catch((error) => console.error(error));

    if (FeatureEnabled("DemoVersion")) {
      setWorkloadFetchIntervalMs(demoVersionWorkloadsFetchIntervalMs);
    }
  }, [api]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/namespaces/annotations")
      .method("get")
      .create()({})
      .then((response) => {
        setNamespaceAnnotations(response?.data?.annotations || []);
      })
      .catch((error) => console.error(error));
  }, [api]);

  const handleProcessRowUpdateError = useCallback((message: string) => {
    setSnackbar({ children: { message }, severity: "error" });
  }, []);

  const handleProcessRowUpdateSuccess = useCallback((message: string | React.ReactNode) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }, []);

  useEffect(() => {
    // TODO - reffer that fact that workloads are now already filtered in backend. namespacesParams can be ignored
    if (namespacesParams && namespacesParams.length === 1) {
      setSelectedNamespaceIds(
        data?.workloads?.filter((w) => w.namespace === namespacesParams[0]).map((w) => w.id) ?? []
      );
    }
  }, [namespacesParams, data]);

  useEffect(() => {
    if (data) {
      setExcludeFromNamespaceAutomationIds(
        data.workloads
          ?.filter((w) => w.isAutomationExcluded || !w.isReadyRecommendation || w.isAutoForced)
          .map((w) => w.id) ?? []
      );
    }
  }, [data]);

  const fetchWorkloads = () => {
    queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
  };

  const fetchWorkloadsProp = useCallback(fetchWorkloads, []);

  const updateAutomation = useUpdateAutomation((usAutomated: boolean) => {
    handleProcessRowUpdateSuccess(
      <span className={"flex"}>
        Workload was {usAutomated ? "automated" : "unautomated"} successfully
        {usAutomated && <ScaleopsIcon width={13} height={13} className="absolute left-[135px] top-[44px]" />}
      </span>
    );
  });

  const fetchAndSetNewPolicies = () => {
    api
      .getFetcher()
      .path("/api/v1/policies")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.policies != undefined) {
            setPolicies(response.data.policies);
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const processRowUpdate = (newRow: components["schemas"]["UtilsWorkload"]): components["schemas"]["UtilsWorkload"] => {
    void updatePolicy(newRow.policyName, newRow.workloadName, newRow.type, newRow.namespace);
    return newRow;
  };

  const updatePolicy = (
    policyName: string | undefined,
    workloadName: string | undefined,
    workloadType: string | undefined,
    namespace: string | undefined
  ): Promise<void> => {
    if (policyName != undefined && workloadName != undefined && workloadType != undefined && namespace != undefined) {
      return api
        .getFetcher()
        .path("/api/v1/policy/{name}/workload/attach")
        .method("patch")
        .create()({
          name: policyName,
          target: workloadType?.toLowerCase() + "-" + workloadName,
          namespaceName: namespace,
        })
        .then(() => {
          fetchWorkloads();
        })
        .catch((error) => console.error(error));
    } else {
      return Promise.resolve();
    }
  };

  const getNamespaceIdsAndNamespaceNames = (annotations: components["schemas"]["UtilsNamespaceAnnotationFilter"]) => {
    let namespaceIds;
    let namespacesNames;

    const namespaceForFilter =
      annotations.annotations && annotations.annotations.length > 0
        ? getNamespacesFromAnnotations(annotations)
        : namespacesParams ?? [];

    const namespaces = (
      (namespacesParams && namespacesParams.length > 0
        ? namespacesParams.filter((ns) => ns && namespaceForFilter.includes(ns))
        : namespaceForFilter) as string[]
    ).filter((ns) => ns !== "scaleops-system");

    switch (!!isNamespacesExclude) {
      case true:
        namespaceIds = data?.workloads?.filter((wl) => !namespaces?.includes(wl.namespace)).map((wl) => wl.id);
        namespacesNames = namespacesForFilter.filter((ns) => !namespaces?.includes(ns));
        break;
      default:
        namespaceIds = data?.workloads?.filter((wl) => namespaces?.includes(wl.namespace)).map((wl) => wl.id);
        namespacesNames = namespaces;
    }

    namespaceIds = namespaceIds?.filter((id) => !excludeFromNamespaceAutomationIds.includes(id)) ?? [];

    return { namespaceIds, namespacesNames };
  };

  const getNamespacesFromAnnotations = (annotations: components["schemas"]["UtilsNamespaceAnnotationFilter"]) => {
    const operator = annotations?.operator || "OR";
    return namespaceObjects
      .filter((ns) => {
        for (const annotation of annotations.annotations || []) {
          const [key, value] = annotation.split("=");
          const match = ns?.metadata?.annotations?.[key] === value;
          switch (operator) {
            case "And":
            case "AND":
            case "and": {
              if (!match) {
                return annotations.exclude;
              }
              break;
            }
            case "Or":
            case "or":
            case "OR": {
              if (match) {
                return !annotations.exclude;
              }
            }
          }
        }
        return annotations.exclude;
      })
      .map((ns) => ns?.metadata?.name);
  };

  const attachPolicyToNamespace = (policyName: string) => {
    const annotations = {
      annotations: namespaceAnnotationParam as string[],
      opeartor: (namespaceAnnotationLogical || "OR") as "OR" | "or" | "Or" | "AND" | "and" | "And",
      exclude: !!namespaceAnnotationExclude,
    };
    const { namespaceIds } = getNamespaceIdsAndNamespaceNames(annotations);
    const Input = {
      namespaces: namespacesParams as string[],
      annotations: annotations,
      policy: policyName,
    };

    updateNamespaceBulk.mutate(Input);

    if (namespaceIds.length > 0) {
      updateOverriddenWorkloads({
        ids: namespaceIds,
        props: { policyName },
      });
    }
  };

  const attachPolicyToCluster = (policyName: string) => {
    updatePolicyByCluster.mutate({ policyName });
  };

  const restoreClusterPolicy = () => restorePolicyOnCluster.mutate();

  const restoreNamespacesPolicy = () => restorePolicyOnNamespace.mutate();

  const automateByFilter = (state: boolean): void => {
    const annotations = {
      annotations: namespaceAnnotationParam as string[],
      opeartor: (namespaceAnnotationLogical || "OR") as "OR" | "or" | "Or" | "AND" | "and" | "And",
      exclude: !!namespaceAnnotationExclude,
    };
    const { namespaceIds } = getNamespaceIdsAndNamespaceNames(annotations);
    const Input = {
      namespaces: namespacesParams as string[],
      annotations: annotations,
      optimize: state,
    };

    updateNamespaceBulk.mutate(Input);

    if (namespaceIds.length > 0) {
      updateOverriddenWorkloads({
        ids: namespaceIds,
        props: { auto: state },
      });
    }
  };

  useEffect(() => {
    fetchAndSetNewPolicies();
    fetchWorkloads();
    const interval = setInterval(() => {
      fetchWorkloads();
    }, 1000 * 20);
    return clearInterval(interval);
  }, [api]);

  const updateAutoInBulk = (
    checked: boolean,
    workloads: {
      name: string;
      type: string;
      namespace: string;
      auto: boolean;
      id: string;
      isAutomationExcluded: boolean | undefined;
    }[]
  ) => {
    const uniqueIds = workloads.filter((wl) => !wl?.isAutomationExcluded).map((wl) => wl.id);

    queryClient.setQueryData(
      [dashboardByNamespace.queryKey, filterQueryParams],
      (oldData: GetDashboardByNamespaceResponse | undefined) => {
        return {
          ...oldData,
          workloads: oldData?.workloads?.map((wl) => {
            if (uniqueIds?.includes(wl.id)) {
              return { ...wl, auto: checked };
            }
            return wl;
          }),
        };
      }
    );

    updateOverriddenWorkloads({
      ids: uniqueIds,
      props: { auto: checked },
    });

    const api = ScaleOps();

    return api
      .getFetcher()
      .path("/api/v1/auto/bulk")
      .method("post")
      .create()({
        workloads: workloads,
        state: checked,
      })
      .then(() => {
        toast.success("Auto updated in bulk", {
          position: "top-center",
        });
        return;
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Failed to update auto in bulk`, {
          position: "top-center",
        });
        uniqueIds.forEach((id) => {
          const previousAutoValue = workloads.find((wl) => wl.id === id)?.auto;
          updateOverriddenWorkloads({
            ids: [id],
            props: { auto: previousAutoValue },
          });
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
      });
  };

  const applyInBulk = (workloads: { name: string; type: string; namespace: string; muttype: string }[]): void => {
    const api = ScaleOps();

    api
      .getFetcher()
      .path("/api/v1/recommendation/apply/bulk")
      .method("patch")
      .create()({
        workloads: workloads,
      })
      .then((response: ApiResponse<components["schemas"]["RecommendationsApplyRecommendationsResponse"]>) => {
        handleProcessRowUpdateSuccess(
          response.ok
            ? `Successfully Applied recommendations to workloads`
            : `Failed to apply recommendations to workloads`
        );
      })
      .catch((error) => {
        console.error(error);
        handleProcessRowUpdateError("Failed to apply recommendations to workloads");
      })
      .finally(() => {
        queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
      });
  };

  const restartInBulk = (workloads: { name: string; type: string; namespace: string }[]): Promise<void> => {
    const request: components["schemas"]["RolloutInput"] = {
      workloads: workloads,
    };
    return api
      .getFetcher()
      .path("/api/v1/recommendation/rollout")
      .method("post")
      .create()(request)
      .then(() => {
        toast.success("Successfully rolled out workloads", {
          position: "top-center",
        });
      })
      .catch((reason) => {
        toast.error("Failed to rollout workloads", {
          position: "top-center",
        });
        console.error(reason);
      });
  };
  const restorePolicyInBulk = (
    workloads: { name: string; type: string; namespace: string; id: string }[]
  ): Promise<void> => {
    const request: components["schemas"]["BulkRestorePolicyInput"] = {
      workloads: workloads,
    };

    return api
      .getFetcher()
      .path("/api/v1/policy/restore-policy/bulk")
      .method("post")
      .create()(request)
      .then(() => {
        // deleteOverriddenWorkloadsProperties({
        //   ids: workloads.map((wl) => wl.id),
        //   propertyNames: ["policyName"],
        // });
        updateOverriddenWorkloadsByUpdateFunction({
          ids: workloads.map((wl) => wl.id),
          updateFunction: (workload) => {
            const smartPolicyName = data?.workloads?.find((wl) => wl.id === workload.id)?.smartPolicyName;
            return { ...workload, policyName: smartPolicyName || "production" };
          },
        });
        toast.success("Successfully restored policy", {
          position: "top-center",
        });
        queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
      })
      .catch((reason) => {
        toast.error("Failed to restore policy", {
          position: "top-center",
        });
        console.error(reason);
      });
  };
  const attachPolicyInBulk = (
    policyName: string,
    workloads: { name: string; type: string; namespace: string; id: string; rollbackPolicyName: string }[]
  ): Promise<void> => {
    const uniqueIds = workloads.map((wl) => wl.id);

    updateOverriddenWorkloads({
      ids: uniqueIds,
      props: { policyName: policyName },
    });

    queryClient.setQueryData(
      [dashboardByNamespace.queryKey, filterQueryParams],
      (oldData: GetDashboardByNamespaceResponse | undefined) => {
        return {
          ...oldData,
          workloads: oldData?.workloads?.map((wl) => {
            if (uniqueIds?.includes(wl.id)) {
              return { ...wl, policyName: policyName };
            }
            return wl;
          }),
        };
      }
    );

    const api = ScaleOps();

    if (policyName !== "") {
      return api
        .getFetcher()
        .path("/api/v1/policy/{name}/workload/attach/bulk")
        .method("patch")
        .create()({
          name: policyName,
          workloads: workloads,
        })
        .then(() => {
          toast.success("Successfully attached policy to workloads", {
            position: "top-center",
          });
        })
        .catch((error: Error & { reason?: string }) => {
          workloads.forEach((wl) => {
            const previousPolicyName = wl.rollbackPolicyName;
            updateOverriddenWorkloads({
              ids: [wl.id],
              props: { policyName: previousPolicyName },
            });
          });
          const forbidden = error?.reason && error.reason === "Forbidden" ? " (Forbidden)" : "";
          toast.error(`Failed to attach policy to workloads${forbidden}`);
          console.log(` Error: ${error.message}`);
        })
        .finally(() => {
          queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
        });
    } else {
      handleProcessRowUpdateError("No policy has set");
      return Promise.resolve();
    }
  };

  const columns = useColumns({
    lastTimeWorkloadRowWasClicked,
    workloadRowClickCount,
    page,
    scaleOpsProduct,
    isWarningTooltipOpen,
    firstRowId,
    setIsWarningTooltipOpen,
    overriddenWorkloadsIds,
    isReadOnlyFrontEnd,
    policyNames: uniquePolicyNamesByProduct,
    api,
    setSnackbar,
    setGitWorkflow,
    deleteOverriddenWorkloadsProperties,
    attachPolicyToWorkload,
    resetRecommendation,
    updateAutomation,
    wasInstalledInTheLast3Days,
    didUserAutomateFirstWorkload,
    setDidUserAutomateFirstWorkload,
    data,
    selectedRows,
    setSelectedRows,
    selectedColumns,
    allRows: allRows as components["schemas"]["UtilsWorkload"][],
  });

  const workloadsAndIssues = () => {
    let workLoadsToSet =
      data?.workloads?.map((workload: components["schemas"]["UtilsWorkload"]) => {
        const wlWithIssues: DashWorkloadWithIssues = {
          ...workload,
        };
        return wlWithIssues;
      }) ?? [];

    workLoadsToSet = workLoadsToSet.map((w) => ({
      ...w,
      savingsAvailable: w.isReadyRecommendation ? w.savingsAvailable : -Infinity,
    }));

    /**
     * Override workloads with overridden workloads / SuperOptimistic updater mode / forced state
     * Note: use the OVERRIDE_WORKLOADS_ENABLED env var to enable this feature
     */
    workLoadsToSet = workLoadsToSet.map((wl) => {
      if (HAS_OVERRIDE_WORKLOADS_ENABLED) return wl;
      const overriddenWorkload = overriddenWorkloads.find((ow) => ow.id === wl.id);
      if (overriddenWorkload) {
        return {
          ...wl,
          ...overriddenWorkload,
        };
      }
      return wl;
    });

    workLoadsToSet.map((wl) => ({
      ...wl,
      activeSavings: wl.auto ? wl.activeSavings : 0,
    }));

    /**
     * Filter by search terms (currentSearchTerm and searchTerms)
     */

    if (currentSearchTerm && currentSearchTerm.length > 0) {
      workLoadsToSet = workLoadsToSet.filter((wl) => {
        const workloadName = getLowerCaseWorkloadName(wl);
        return workloadName.includes(currentSearchTerm);
      });
    }

    if (searchTerms && searchTerms.length > 0) {
      workLoadsToSet = workLoadsToSet.filter((wl) => {
        const workloadName = getLowerCaseWorkloadName(wl);
        if (SEARCH_LOGIC === "OR") {
          return searchTerms.some((term) => {
            const lowerCaseTerm = term ? term.toLowerCase() : "";
            return workloadName.includes(lowerCaseTerm);
          });
        } else {
          return searchTerms.every((term) => {
            const lowerCaseTerm = term ? term.toLowerCase() : "";
            return workloadName.includes(lowerCaseTerm);
          });
        }
      });
    }

    workLoadsToSet = workLoadsToSet.map((wl) => {
      wl.totalCost = wl.totalCost ?? 0;
      return wl;
    });

    /** ** ** ** ** ** ** ** ** ** **
     *
     * The column sort behavior
     *
     ** ** ** ** ** ** ** ** ** ** **/

    if (sortField && sortDirection) {
      switch (true) {
        case sortField === OverviewTableFields.Workload:
          workLoadsToSet.sort((a, b) => {
            const workloadNameA = `${a.namespace}/${a.workloadName.replace(/^scaleops-rollout-/, "")}`;
            const workloadNameB = `${b.namespace}/${b.workloadName.replace(/^scaleops-rollout-/, "")}`;
            if (workloadNameA < workloadNameB) {
              return sortDirection === ASC ? -1 : 1;
            } else if (workloadNameA > workloadNameB) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.TotalCost:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "totalCost", true);
          break;
        case sortField === OverviewTableFields.SavingsAvailable:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "savingsAvailable", true);
          break;
        case sortField === OverviewTableFields.ActiveSavings:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "activeSavings");
          break;
        case sortField === OverviewTableFields.CPUDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "cpuRequests");
          break;
        case sortField === OverviewTableFields.OriginalCPURequest:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "ownerCpuRequests");
          break;
        case sortField === OverviewTableFields.MemoryDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "memRequests");
          break;
        case sortField === OverviewTableFields.OriginalMemoryRequest:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "ownerMemoryRequests");
          break;
        case sortField === OverviewTableFields.Replicas:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "runningReplicas");
          break;
        case sortField === OverviewTableFields.PolicyName:
          workLoadsToSet.sort((a, b) => {
            if (a.policyName < b.policyName) {
              return sortDirection === ASC ? -1 : 1;
            } else if (a.policyName > b.policyName) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.Automated:
          workLoadsToSet.sort((a, b) => {
            if (a.auto && !b.auto) {
              return sortDirection === ASC ? -1 : 1;
            } else if (!a.auto && b.auto) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.Unevictable:
          workLoadsToSet.sort((a, b) => {
            if (
              (a.smartPolicyWorkloadType === "Unevictable by PDB" ||
                a.smartPolicyWorkloadType === "Unevictable by Annotation") &&
              !(
                b.smartPolicyWorkloadType === "Unevictable by PDB" ||
                b.smartPolicyWorkloadType === "Unevictable by Annotation"
              )
            ) {
              return sortDirection === ASC ? -1 : 1;
            } else if (
              !(
                a.smartPolicyWorkloadType === "Unevictable by PDB" ||
                a.smartPolicyWorkloadType === "Unevictable by Annotation"
              ) &&
              (b.smartPolicyWorkloadType === "Unevictable by PDB" ||
                b.smartPolicyWorkloadType === "Unevictable by Annotation")
            ) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.PredictableSavings:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "replicasPredictableAvailableSavings", true);
          break;
        case sortField === OverviewTableFields.ReplicasDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "recommendedReplicas");
          break;
        case sortField === OverviewTableFields.MinReplicasDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "recommendedMinReplicas");
          break;
        case sortField === OverviewTableFields.IsIdleStable:
          workLoadsToSet.sort((a, b) => {
            if (a.isIdleStable && !b.isIdleStable) {
              return sortDirection === ASC ? -1 : 1;
            } else if (!a.isIdleStable && b.isIdleStable) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.OriginalGPURequest:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "ownerGpuRequests");
          break;

        default:
          break;
      }

      setRowCount(workLoadsToSet.length);
      return workLoadsToSet;
    }

    /** ** ** ** ** ** ** ** ** ** **
     *
     * The default sort behavior
     *
     ** ** ** ** ** ** ** ** ** ** **/

    /**
     * Sort by savings available
     */
    workLoadsToSet.sort((a, b) => {
      if (a.savingsAvailable > b.savingsAvailable) {
        return -1;
      } else if (a.savingsAvailable < b.savingsAvailable) {
        return 1;
      } else {
        return 0;
      }
    });

    /**
     * Sort by automated
     */
    workLoadsToSet.sort((a, b) => {
      if (!!a.auto && !b.auto) {
        return 1;
      } else if (!a.auto && !!b.auto) {
        return -1;
      } else {
        return 0;
      }
    });

    /**
     * Sort by original order
     */
    const currentCluster = data?.clusters?.[0];
    if (originalOrder.current?.length === 0 || originalSortOrderCluster.current !== currentCluster) {
      originalOrder.current = workLoadsToSet.map((wl) => wl.id);
      originalSortOrderCluster.current = currentCluster;
    }

    /**
     * Sort by original order
     */
    if (originalOrder.current?.length > 0) {
      workLoadsToSet.sort((a, b) => {
        const aIndex = originalOrder.current.indexOf(a.id);
        const bIndex = originalOrder.current.indexOf(b.id);
        if (aIndex < bIndex) {
          return -1;
        } else if (aIndex > bIndex) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    /**
     * Show params.row.isAutomationExcluded = true workloads last
     */
    workLoadsToSet.sort((a, b) => {
      if (!a.isAutomationExcluded && !!b.isAutomationExcluded == true) {
        return -1;
      } else if (!!a.isAutomationExcluded && !b.isAutomationExcluded) {
        return 1;
      } else {
        return 0;
      }
    });

    /**
     * Sort by if workload include prometheus string show last
     */
    const PROMETHEUS_STRING = "scaleops-prometheus";
    workLoadsToSet.sort((a, b) => {
      if (
        a.workloadName.toLowerCase().includes(PROMETHEUS_STRING) &&
        !b.workloadName.toLowerCase().includes(PROMETHEUS_STRING)
      ) {
        return 1;
      } else if (
        !a.workloadName.toLowerCase().includes(PROMETHEUS_STRING) &&
        b.workloadName.toLowerCase().includes(PROMETHEUS_STRING)
      ) {
        return -1;
      }
      return 0;
    });

    /*
     * sort by show "namespace": "scaleops-system" last
     */
    workLoadsToSet.sort((a, b) => {
      if (a.namespace == SCALEOPS_SYSTEM_NAMESPACE && b.namespace != SCALEOPS_SYSTEM_NAMESPACE) {
        return 1;
      } else if (a.namespace != SCALEOPS_SYSTEM_NAMESPACE && b.namespace == SCALEOPS_SYSTEM_NAMESPACE) {
        return -1;
      } else {
        return 0;
      }
    });

    /**
     * Show isRecommendationReady = false workloads last
     */
    workLoadsToSet.sort((a, b) => {
      if (a.isReadyRecommendation == false && b.isReadyRecommendation == true) {
        return 1;
      } else if (a.isReadyRecommendation == true && b.isReadyRecommendation == false) {
        return -1;
      } else {
        return 0;
      }
    });

    setRowCount(workLoadsToSet.length);
    return workLoadsToSet;
  };

  useEffect(() => {
    const sliceStart = page * pageSize;
    const sliceEnd = sliceStart + pageSize;
    setDisableAnimation((data?.workloads?.length ?? 0) > STOP_ANIMATION_THRESHOLD);
    const wl = workloadsAndIssues();
    setAllRows(wl);
    const rowsToSet = wl.slice(sliceStart, sliceEnd);
    if (selectAllRowsOnInitialLoad && rowsToSet.length > 0) {
      setSelectedRows(rowsToSet);
      setSelectAllRowsOnInitialLoad(false);
    }
    setRows(rowsToSet);
    setFirstRowId(rowsToSet[0]?.id);
  }, [
    data,
    currentSearchTerm,
    searchTerms,
    overriddenWorkloads,
    originalOrder,
    page,
    pageSize,
    sortField,
    sortDirection,
  ]);

  const [showAllocatable, setShowAllocatable] = useState<boolean>(!FeatureEnabled("DemoVersion"));

  const updateShowAllocatable = (value: boolean) => {
    if (showAllocatable !== value && !isBigCluster) {
      setShowAllocatable(value);
    }
  };
  useEffect(() => {
    if (FeatureEnabled("DemoVersion")) {
      return;
    }
    if (namespacesParams?.length !== undefined && namespacesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (policiesParams?.length !== undefined && policiesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (priorityClassNamesParams?.length !== undefined && priorityClassNamesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (optimizationGapsParams?.length !== undefined && optimizationGapsParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (selectedLabelNames?.length !== undefined && selectedLabelNames?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (typesParams?.length !== undefined && typesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (isAutomated !== undefined && isAutomated !== null && isAutomated) {
      updateShowAllocatable(false);
      return;
    }
    if (hasMinReplicasSavings !== undefined && hasMinReplicasSavings !== null && hasMinReplicasSavings) {
      updateShowAllocatable(false);
      return;
    }
    if (hasHpaAutomatedPolicy !== undefined && hasHpaAutomatedPolicy !== null && hasHpaAutomatedPolicy) {
      updateShowAllocatable(false);
      return;
    }
    if (hasHpaUnAutomatedPolicy !== undefined && hasHpaUnAutomatedPolicy !== null && hasHpaUnAutomatedPolicy) {
      updateShowAllocatable(false);
      return;
    }
    if (hasHpa !== undefined && hasHpa !== null && hasHpa) {
      updateShowAllocatable(false);
      return;
    }
    if (isMisconfiguredHpa !== undefined && isMisconfiguredHpa !== null && isMisconfiguredHpa) {
      updateShowAllocatable(false);
      return;
    }
    if (isHpaPredictable !== undefined && isHpaPredictable !== null && isHpaPredictable) {
      updateShowAllocatable(false);
      return;
    }
    if (isUnAutomated !== undefined && isUnAutomated !== null && isUnAutomated) {
      updateShowAllocatable(false);
      return;
    }
    if (isOverProvisioned !== undefined && isOverProvisioned !== null && isOverProvisioned) {
      updateShowAllocatable(false);
      return;
    }
    if (isUnderProvisioned !== undefined && isUnderProvisioned !== null && isUnderProvisioned) {
      updateShowAllocatable(false);
      return;
    }
    if (isPdb !== undefined && isPdb !== null && isPdb) {
      updateShowAllocatable(false);
      return;
    }
    if (outOfMemory !== undefined && outOfMemory !== null && outOfMemory) {
      updateShowAllocatable(false);
      return;
    }
    if (availableSavingsFilter !== undefined && availableSavingsFilter !== null && availableSavingsFilter) {
      updateShowAllocatable(false);
      return;
    }
    updateShowAllocatable(!FeatureEnabled("DemoVersion"));
  }, [
    namespacesParams,
    policiesParams,
    priorityClassNamesParams,
    optimizationGapsParams,
    selectedLabelNames,
    typesParams,
    isAutomated,
    hasMinReplicasSavings,
    hasHpaAutomatedPolicy,
    hasHpaUnAutomatedPolicy,
    hasHpa,
    isMisconfiguredHpa,
    isHpaPredictable,
    isUnAutomated,
    isOverProvisioned,
    isUnderProvisioned,
    isPdb,
    outOfMemory,
    availableSavingsFilter,
  ]);

  useEffect(() => {
    if (!isLoading && !rowsWasInitialized) {
      setRowsWasInitialized(true);
    }
  }, [rows]);

  const totalNumberOfPages = Math.ceil(rows.length / pageSize);
  const isPageLastPage = page === totalNumberOfPages - 1 && rows.length > pageSize;

  const isTableLoading = isLoading || !rowsWasInitialized;

  return (
    <div className="flex flex-col gap-3">
      {selectedWorkload && (
        <ErrorBoundary fallback={<DefaultFallback message="An error occurred while opening Policy Tuning" />}>
          <PolicyTuning
            selectedWorkload={selectedWorkload}
            setSelectedWorkload={setSelectedWorkload}
            isOpen={isPolicyTuningDialogOpen}
            onClose={() => {
              setSelectedWorkload(undefined);
              setSelectedWorkloadOverviewId(undefined);
              setHideSuffix(false);
              setDisplayName(undefined);
              setIsPolicyTuningDialogOpen(false);
            }}
            fetchWorkloads={fetchWorkloadsProp}
            hideWorkloadSuffix={hideSuffix}
            displayName={displayName}
            scaleOpsProduct={scaleOpsProduct}
          />
        </ErrorBoundary>
      )}
      {HAS_NODE_ACTIONS_RECOMMENDATION_FF && (
        <NodeActionRecommendation
          totalAutomatedWorkloads={data?.totalNamespaceSummary?.automatedWorkloads || 0}
          totalUnAutomatedWorkloads={data?.totalNamespaceSummary?.unautomatedWorkloads || 0}
        />
      )}
      <div style={{ paddingTop: ".375rem" }}>
        <Paper
          sx={{
            width: "100%",
            boxShadow: "none",
            border: "none",
            borderTopLeftRadius: "0rem",
          }}
          className="p-[1.25rem] relative"
        >
          <EditToolbar
            canEditAllWorkloads={data?.metadata?.canEditAllWorkloads}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            fetchWorkloads={fetchWorkloads}
            updateAutoInBulk={updateAutoInBulk}
            applyInBulk={applyInBulk}
            namespaces={namespacesForFilter}
            workloadsLabels={workloadsLabels}
            workloadsAnnotations={workloadsAnnotations}
            types={typesForFilter}
            priorityClassNames={priorityClassNames}
            optimizationGaps={optimizationGaps}
            automateByFilter={automateByFilter}
            policies={policies}
            attachPolicyToNamespace={attachPolicyToNamespace}
            attachPolicyToCluster={attachPolicyToCluster}
            attachPolicyInBulk={attachPolicyInBulk}
            restartInBulk={restartInBulk}
            restoreClusterPolicy={restoreClusterPolicy}
            restorePolicyInBulk={restorePolicyInBulk}
            restoreNamespacesPolicy={restoreNamespacesPolicy}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            availableColumns={availableColumns}
            serializedCappedStatuses={serializedCappingStatuses}
            namespaceAnnotations={namespaceAnnotations}
            numberOfRows={allRows.length}
            scaleOpsProduct={scaleOpsProduct}
          />
          <DataGrid
            key={`${String(windowSize.width)}-${String(windowSize.height)}-${String(scaleOpsProduct)}`}
            pagination={true}
            headerHeight={HEADER_HEIGHT}
            autoHeight={allRows.length <= pageSize}
            rowHeight={ROW_HEIGHT}
            checkboxSelection
            sx={{
              ...getDataGridSx(true),
              width: "100%",
              border: isPageLastPage ? undefined : "none",
              height: pageSize * ROW_HEIGHT + HEADER_HEIGHT + FOOTER_HEIGHT,
              "& .MuiDataGrid-cellCheckbox": {
                borderLeft: ".0625rem solid #c4c4c4",
              },
              "& .workloadCellContent": {
                padding: "0rem",
              },
              "& NaNpxovePadding.MuiDataGrid-cell": {
                padding: "0rem",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                opacity: isLoading ? "50%" : "100%",
                cursor: isLoading ? "not-allowed" : "pointer",
              },
            }}
            columns={columns}
            isCellEditable={(params) => {
              return !!params.row.isEditable;
            }}
            rows={rows}
            initialState={{
              pagination: {
                pageSize: pageSize,
              },
            }}
            pageSize={pageSize}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            loading={isTableLoading}
            experimentalFeatures={{ newEditingApi: true }}
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={(x) => {
              if (isInitialSortModel) setIsInitialSortModel(false);
              setSortField(x[0]?.field);
              setSortDirection(x[0]?.sort);
            }}
            sortModel={
              isInitialSortModel && !sortField && !sortDirection
                ? INITIAL_SORT_MODEL
                : [
                    {
                      field: String(sortField),
                      sort: String(sortDirection) as GridSortDirection,
                    },
                  ]
            }
            rowCount={rowCount}
            style={{ borderRadius: ".3125rem" }}
            processRowUpdate={processRowUpdate}
            disableSelectionOnClick
            onRowClick={(params: GridRowParams<components["schemas"]["UtilsWorkload"]>) => {
              setLastTimeWorkloadRowWasClicked(new Date().getTime() / 1000);
              setWorkloadRowClickCount(Number(workloadRowClickCount) + 1);
              const selection = window.getSelection();
              const selectedText = selection?.toString();
              if (highlightedSelection !== selectedText) {
                setHighlightedSelection(selectedText);
              }

              if (
                params.row.isReadyRecommendation &&
                (!selectedText?.length || selectedText.length === 0 || selectedText === highlightedSelection)
              ) {
                setSelectedWorkloadOverviewId(params.row.id);
                setHideSuffix(!!params.row.hideSuffix);
                setDisplayName(params.row.displayName);
                setIsPolicyTuningDialogOpen(true);
              }
            }}
            getRowClassName={(params) =>
              clsx({
                automatedRow: params.row.auto,
                "cursor-pointer group": params.row.isReadyRecommendation,
              })
            }
            keepNonExistentRowsSelected
            onSelectionModelChange={(selectedIds) => {
              switch (true) {
                case isLoading:
                  break;
                case Number(selectedIds.length) === pageSize && selectedRows && allRows.length !== selectedRows.length:
                  setSelectedRows([...allRows]);
                  break;
                case Number(selectedIds.length) === allRows.length - pageSize && selectedIds.length !== 1:
                  setSelectedRows([]);
                  break;
                case Number(selectedIds.length) === 1:
                  setSelectedRows(allRows.filter((row) => row.id === selectedIds[0]));
                  break;
                default:
                  setSelectedRows(allRows.filter((row) => selectedIds.includes(row.id)));
                  break;
              }
            }}
            selectionModel={selectedRows?.map((row) => row.id) ?? []}
            hideFooterSelectedRowCount
          />
          {selectedRows && selectedRows.length > 0 && (
            <Typography variant="body2" className="w-fit left-[1.0625rem] top-[-2.25rem] relative">
              {selectedRows.length} {selectedRows.length === 1 ? "workload" : "workloads"} selected
            </Typography>
          )}
          <OverviewExportCSVButton
            csvData={[...(allRows ?? [])]}
            selectedColumns={selectedColumns}
            hideExport={(selectedRows && selectedRows.length !== 0) || !rows || rows.length === 0}
          />
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert severity={snackbar?.severity} onClose={handleCloseSnackbar}>
                {snackbar?.children?.message}
              </Alert>
            </Snackbar>
          )}
          <GitConfigPopup id={gitWorkflow?.workloadName} workload={gitWorkflow} setWorkload={setGitWorkflow} />
        </Paper>
      </div>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert severity={snackbar?.severity} onClose={handleCloseSnackbar}>
            {snackbar?.children?.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export interface policyDiaglogAutoToggleParams {
  isFreeDistLimited: boolean;
  isAutoCheck: boolean;
  setIsAutoCheck: React.Dispatch<React.SetStateAction<boolean>>;
  isAutoSideEffectCb: (check: boolean) => void;
}

import { HAS_MULTI_PRODUCT_LAYOUT } from "./developmentFeatureFlags";
import useStateWithSessionStorage from "./useStateWithSessionStorage";

const useHasMultiProductLayout = (): boolean => {
  const [hasMultiProductLayout] = useStateWithSessionStorage<boolean>({
    sessionStorageKey: "hasMultiProductLayout",
    defaultValue: false,
    valueFormatter: (value) => value === "true",
  });

  if (HAS_MULTI_PRODUCT_LAYOUT || hasMultiProductLayout) {
    return true;
  }

  return false;
};

export default useHasMultiProductLayout;
